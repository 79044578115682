import React from "react";
import { Box, Typography, useTheme, useMediaQuery, Tooltip, Link } from "@mui/material";
import LocalImages from "../../utils/localImages";

const Footer = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(1000));
  return (
    <>
      {!isMobile ? (
        <>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              justifyContent: "space-between",
              padding: "20px 0",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-evenly",
                width: "50%",
              }}
            >
              <Typography sx={{ color: "#424546", fontSize: "16px" }}>
                © Copyright TradeSphere Group 2023
              </Typography>
              <a style={{textDecoration:"none"}} href="https://infinitetradesstgclientweb.appskeeper.in/terms">
              <Typography sx={{ color: "#424546", fontSize: "16px" }}>
                Terms of Use
              </Typography>
              </a>
              <a style={{textDecoration:"none"}} href="https://infinitetradesstgclientweb.appskeeper.in/privacyPolicy">
                <Typography sx={{ color: "#424546", fontSize: "16px" }}>
                Privacy Policy
                </Typography>
              </a>

            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-evenly",
                width: "15%",
              }}
            >
              <a style={{textDecoration:"none"}} href="#contact">
              <Typography sx={{ color: "#424546", fontSize: "16px" }}>
                Contact Us
              </Typography>
              </a>
              <Link href="https://www.facebook.com/people/Infinite-Trades/100090863791910/">
              <img
                style={{ width: "20px", height: "20px" }}
                src={LocalImages.Facebook}
                alt="Linkedin"
              />
              </Link>
              
              <Link href="https://www.linkedin.com/company/infinite-trades/?viewAsMember=true">
              <img
                style={{ width: "20px", height: "20px" }}
                src={LocalImages.Linkedin}
                alt="Linkedin"
              />
              </Link>
              
            </Box>
          </Box>
        </>
      ) : (
        <>
        <Box sx={{width:"70%", ml:"11%", padding:"40px 0"}}>
        <Typography sx={{ color: "#424546", fontSize: "14px",textAlign:"left", mb:"14%" }}>
                © Copyright TradeSphere Group 2023
              </Typography>
              <a href="https://infinitetradesstgclientweb.appskeeper.in/terms">
              <Typography sx={{ color: "#424546", fontSize: "14px",textAlign:"left", mb:"14%" }}>
                Terms of Use
              </Typography>
              </a>
              <a href="https://infinitetradesstgclientweb.appskeeper.in/privacyPolicy">
              <Typography sx={{ color: "#424546", fontSize: "14px", textAlign:"left", mb:"14%" }}>
                Privacy Policy
              </Typography>
              </a>
              <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Typography sx={{ color: "#424546", fontSize: "14px", textAlign:"left", marginRight:"70px", width:"150px" }}>
                Contact Us
              </Typography>
              <Tooltip title="Under Development">
              <Box sx={{display:"flex",
              flexDirection:"row",
              width:"200px"
            }}>
              <img
                style={{ width: "20px", height: "20px", marginRight:"20%" }}
                src={LocalImages.Facebook}
                alt="Linkedin"
              />
              <img
                style={{ width: "20px", height: "20px",  }}
                src={LocalImages.Linkedin}
                alt="Linkedin"
              />
              </Box>
              </Tooltip>
              
            </Box>
        </Box>
        
        </>
      )}
    </>
  );
};

export default Footer;
