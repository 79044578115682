const LocalImages = {
    How1:"/images/hw1.png",
    How2:"/images/hw2.png",
    How3:"/images/hw3.png",
    How4:"/images/hw4.png",
    Logo: "/images/logo.png",
    LandingBackground: "/images/Landing.png",
    Step1:"/images/step1.png",
    Step2:"/images/step2.png",
    Step3:"/images/step3.png",
    Step4:"/images/step4.png",
    Mask:"/images/Mask.png",
    Work:"/images/working.png",
    Boot:"/images/boot.png",
    Call:"/images/phone.png",
    Mail:"/images/mail.png",
    Map:"/images/location.png",
    Linkedin:"/images/Linkedin.png",
    Facebook:"/images/facebook.png",
    Landing:"/images/LandingM.png",
    MaskG:"/images/MaskG.png",
    Ham:"/images/hamburger.png",
  };
  export default LocalImages;