import React from "react";
import { Typography, Box, useTheme, useMediaQuery } from "@mui/material";

const WhyChoose = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(1024));
  return (
    <>
      {!isMobile ? (
        <>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: "5%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-evenly",
                alignItems: "center",
                width: "100% !important",
              }}
            >
              <Box
                sx={{ height: "5px", backgroundColor: "#ffffff", width: "40%" }}
              />
              <Typography
                sx={{
                  fontSize: "24px",
                  fontWeight: "600",
                  color: "#4f4f4f",
                  width: "20%",
                }}
              >
                WHY CHOOSE US
              </Typography>
              <Box
                sx={{ height: "5px", backgroundColor: "#0455bf", width: "40%" }}
              />
            </Box>

            <Typography
              sx={{
                width: "890px",
                color: "#333333",
                fontSize: "16px",
                mt: "2%",
                mb: "2%",
              }}
              id="how"
            >
              Developed by Maintenance and Project Industry specialists, Our
              Streamilined platform aims to narrow the gap from client need to
              services delivered
            </Typography>
          </Box>
        </>
      ) : (
        <>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: "10%",
              width: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-evenly",
                alignItems: "center",
                width: "100% !important",
              }}
            >
              <Box
                sx={{
                  height: "5px",
                  backgroundColor: "transparent",
                  width: "20%",
                }}
              />
              <Typography
                sx={{
                  fontSize: "24px",
                  fontWeight: "600",
                  color: "#4f4f4f",
                  width: "60%",
                }}
              >
                WHY CHOOSE US
              </Typography>
              <Box
                sx={{ height: "5px", backgroundColor: "#0455bf", width: "20%" }}
              />
            </Box>

            <Typography
              sx={{
                width: "327px",
                color: "#333333",
                fontSize: "14px",
                mt: "6%",
              }}
            >
              Developed by Maintenance and Project Industry specialists, Our
              Streamilined platform aims to narrow the gap from client need to
              services delivered
            </Typography>
          </Box>
        </>
      )}
    </>
  );
};

export default WhyChoose;
