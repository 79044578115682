import React from "react";
import {
  Typography,
  Box,
  TextField,
  Button,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import LocalImages from "../../utils/localImages";
import { Form, Formik } from "formik";
// import NormalInput from "../inputField";

const Contact = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(1024));
  const ALPHA_REGEX = /^[a-zA-Z-" "]+$/;
  const handleSubmit = (value: any) => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        platform: "3",
        api_key: "1234",
        language: "en",
        Authorization: "Basic aW5maW5pdGU6aW5maW5pdGVAMTI",
      },
      body: JSON.stringify({ ...value }),
    };
    fetch(
      "https://infinitetradestgapi.appskeeper.in/api/v1/common/landing-page",
      requestOptions
    ).then((response) => console.log(response, "response"));
    if (
      value.name !== "" &&
      value.subject !== "" &&
      value.message !== "" &&
      value.email !== ""
    ) {
      alert("Your request has been sent");
      window.location.reload();
    } else {
      alert("Please fill all the fields ");
    }
  };
  return (
    <>
      <Formik
        onSubmit={handleSubmit}
        initialValues={{
          name: "",
          email: "",
          subject: "",
          message: "",
        }}
      >
        {({
          isValid,
          dirty,
          values,
          setFieldValue,
          setFieldTouched,
          touched,
          errors,
          getFieldProps,
        }) => {
          return (
            <>
              {!isMobile ? (
                <>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginTop: "8%",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-evenly",
                        alignItems: "center",
                        width: "100% !important",
                      }}
                    >
                      <Box
                        sx={{
                          height: "5px",
                          backgroundColor: "#ffffff",
                          width: "40%",
                        }}
                      />
                      <Typography
                        sx={{
                          fontSize: "24px",
                          fontWeight: "600",
                          color: "#4f4f4f",
                          width: "20%",
                        }}
                      >
                        CONTACT US
                      </Typography>
                      <Box
                        sx={{
                          height: "5px",
                          backgroundColor: "#0455bf",
                          width: "40%",
                        }}
                      />
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "60%",
                        margin: "5% 0",
                      }}
                    >
                      <Box>
                        <Typography
                          sx={{
                            fontSize: "16px",
                            color: "#4f4f4f",
                            fontWeight: "bold",
                          }}
                        >
                          Fill in your details and we’ll be in touch.
                        </Typography>

                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "62%",
                            marginTop: "10%",
                          }}
                        >
                          <img src={LocalImages.Call} alt="Call" />
                          <Box>
                            <Typography
                              sx={{
                                fontSize: "16px",
                                fontWeight: "500",
                              }}
                            >
                              <a
                                style={{
                                  textDecoration: "none",
                                  color: "#4f4f4f",
                                }}
                                href="tel: 1300 463 46483"
                              >
                                1300 463 46483
                              </a>
                            </Typography>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "84%",
                            marginTop: "8%",
                          }}
                        >
                          <img src={LocalImages.Mail} alt="Call" />
                          <Typography
                            sx={{
                              color: "#4f4f4f",
                              fontSize: "16px",
                              fontWeight: "500",
                            }}
                          >
                            support@infinitetrades.au
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "80%",
                            marginTop: "8%",
                          }}
                        >
                          <img src={LocalImages.Map} alt="Call" />
                          <Typography
                            sx={{
                              color: "#4f4f4f",
                              fontSize: "16px",
                              fontWeight: "500",
                            }}
                          >
                            <a
                              style={{
                                textDecoration: "none",
                                color: "#4f4f4f",
                              }}
                              href="https://www.google.com/maps/place/Trade+Sphere+Group+Pty+Ltd/@-33.7656968,150.6800152,17z/data=!3m2!4b1!5s0x6b128f4e6a004215:0x8acd3bc1f2dc827f!4m5!3m4!1s0x6b128fcff91e8427:0x93dd5126d85928af!8m2!3d-33.7656968!4d150.6822092"
                              target="_blank"
                              rel="noreferrer" 
                            >
                              Australia Wide Services{" "}
                            </a>
                          </Typography>
                        </Box>
                      </Box>
                      <Formik
                        onSubmit={handleSubmit}
                        initialValues={{
                          name: "",
                          email: "",
                          subject: "",
                          message: "",
                        }}
                      >
                        {({
                          isValid,
                          dirty,
                          values,
                          setFieldValue,
                          setFieldTouched,
                          touched,
                          errors,
                          getFieldProps,
                        }) => {
                          return (
                            <Form>
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <TextField
                                  label="Full Name"
                                  onChange={(e) =>
                                    setFieldValue("name", e.target.value)
                                  }
                                  type="text"
                                  onKeyDown={(event) => {
                                    if (!ALPHA_REGEX.test(event.key)) {
                                      event.preventDefault();
                                    }
                                  }}
                                  inputProps={{ maxLength: 50, minLength: 3 }}
                                  value={values.name}
                                  sx={{
                                    width: "370px",
                                    marginBottom: "8%",
                                    borderRadius: "0px",
                                    [`& fieldset`]: {
                                      borderRadius: 0,
                                    },
                                  }}
                                />

                                <TextField
                                  label="Email Address"
                                  type="email"
                                  onChange={(e) =>
                                    setFieldValue("email", e.target.value)
                                  }
                                  value={values.email}
                                  sx={{
                                    width: "370px",
                                    marginBottom: "8%",
                                    borderRadius: "0px",
                                    [`& fieldset`]: {
                                      borderRadius: 0,
                                    },
                                  }}
                                />

                                <TextField
                                  label="Subject"
                                  onChange={(e) =>
                                    setFieldValue("subject", e.target.value)
                                  }
                                  inputProps={{ maxLength: 100 }}
                                  value={values.subject}
                                  sx={{
                                    width: "370px",
                                    marginBottom: "8%",
                                    borderRadius: "0px",
                                    [`& fieldset`]: {
                                      borderRadius: 0,
                                    },
                                  }}
                                />

                                <TextField
                                  label="Message"
                                  onChange={(e) =>
                                    setFieldValue("message", e.target.value)
                                  }
                                  inputProps={{ maxLength: 250 }}
                                  value={values.message}
                                  sx={{
                                    width: "370px",
                                    marginBottom: "8%",
                                    height: "200px",
                                    borderRadius: "0px",
                                    [`& fieldset`]: {
                                      borderRadius: 0,
                                    },
                                    "& .MuiInputBase-root": {
                                      height: "200px",
                                    },
                                  }}
                                />

                                <Button
                                  type="submit"
                                  sx={{
                                    backgroundColor: "#0da4ce",
                                    width: "370px",
                                    padding: "10px 0",
                                    fontWeight: "600",
                                    color: "#fff",
                                    marginTop: "6%",
                                    borderRadius: "0px",
                                    "&:hover": {
                                      backgroundColor: "#097390",
                                      color: "#fff",
                                    },
                                  }}
                                >
                                  SUBMIT
                                </Button>
                              </Box>
                            </Form>
                          );
                        }}
                      </Formik>
                    </Box>
                  </Box>
                </>
              ) : (
                <>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginTop: "8%",
                      width: "100%",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-evenly",
                        alignItems: "center",
                        width: "100% !important",
                      }}
                    >
                      <Box
                        sx={{
                          height: "5px",
                          backgroundColor: "#ffffff",
                          width: "20%",
                        }}
                      />
                      <Typography
                        sx={{
                          fontSize: "24px",
                          fontWeight: "600",
                          color: "#4f4f4f",
                          width: "60%",
                        }}
                      >
                        CONTACT US
                      </Typography>
                      <Box
                        sx={{
                          height: "5px",
                          backgroundColor: "#0455bf",
                          width: "20%",
                        }}
                      />
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        flexDirection: "column",
                        width: "80%",
                        margin: "5% 0",
                      }}
                    >
                      <Box>
                        <Typography
                          sx={{
                            fontSize: "14px",
                            color: "#4f4f4f",
                            fontWeight: "bold",
                            width: "327px",
                          }}
                        >
                          Fill in your details and we’ll be in touch.
                        </Typography>

                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-evenly",
                            width: "70%",
                            marginTop: "10%",
                          }}
                        >
                          <img src={LocalImages.Call} alt="Call" />
                          <Typography
                            sx={{
                              color: "#4f4f4f",
                              fontSize: "14px",
                              fontWeight: "500",
                            }}
                          >
                            <a
                              style={{
                                textDecoration: "none",
                                color: "#4f4f4f",
                              }}
                              href="tel: 1300 463 46483"
                            >
                              1300 463 46483
                            </a>
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-evenly",
                            width: "100%",
                            marginTop: "8%",
                          }}
                        >
                          <img src={LocalImages.Mail} alt="Call" />
                          <Typography
                            sx={{
                              color: "#4f4f4f",
                              fontSize: "14px",
                              fontWeight: "500",
                            }}
                          >
                            support@infinitetrades.au
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-evenly",
                            width: "83%",
                            marginTop: "8%",
                          }}
                        >
                          <img src={LocalImages.Map} alt="Call" />
                          <Typography
                            sx={{
                              color: "#4f4f4f",
                              fontSize: "14px",
                              fontWeight: "500",
                            }}
                          >
                            <a
                              style={{
                                textDecoration: "none",
                                color: "#4f4f4f",
                              }}
                              
                              href="https://www.google.com/maps/place/Trade+Sphere+Group+Pty+Ltd/@-33.7656968,150.6800152,17z/data=!3m2!4b1!5s0x6b128f4e6a004215:0x8acd3bc1f2dc827f!4m5!3m4!1s0x6b128fcff91e8427:0x93dd5126d85928af!8m2!3d-33.7656968!4d150.6822092"
                              target="_blank"
                              rel="noreferrer" 
                            >
                              Australia Wide Services{" "}
                            </a>
                          </Typography>
                        </Box>
                      </Box>
                      <Form>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            mt: "20%",
                          }}
                        >
                          <TextField
                            label="Full Name"
                            onChange={(e) =>
                              setFieldValue("name", e.target.value)
                            }
                            onKeyDown={(event) => {
                              if (!ALPHA_REGEX.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                            inputProps={{ maxLength: 50, minLength: 3 }}
                            value={values.name}
                            sx={{
                              width: "325px",
                              marginBottom: "8%",
                              borderRadius: "0px",

                              [`& fieldset`]: {
                                borderRadius: 0,
                              },
                            }}
                          />

                          <TextField
                            label="Email Address"
                            type="email"
                            onChange={(e) =>
                              setFieldValue("email", e.target.value)
                            }
                            value={values.email}
                            sx={{
                              width: "325px",
                              marginBottom: "8%",
                              borderRadius: "0px",
                              [`& fieldset`]: {
                                borderRadius: 0,
                              },
                            }}
                          />

                          <TextField
                            label="Subject"
                            onChange={(e) =>
                              setFieldValue("subject", e.target.value)
                            }
                            inputProps={{ maxLength: 100}}
                            value={values.subject}
                            sx={{
                              width: "325px",
                              marginBottom: "8%",
                              borderRadius: "0px",

                              [`& fieldset`]: {
                                borderRadius: 0,
                              },
                            }}
                          />

                          <TextField
                            label="Message"
                            onChange={(e) =>
                              setFieldValue("message", e.target.value)
                            }
                            value={values.message}
                            inputProps={{ maxLength: 250}}
                            sx={{
                              width: "325px",
                              marginBottom: "8%",
                              borderRadius: "0px",

                              [`& fieldset`]: {
                                borderRadius: 0,
                              },

                              "& .MuiInputBase-root": {
                                height: "220px",
                              },
                            }}
                          />

                          <Button
                            type="submit"
                            sx={{
                              backgroundColor: "#0da4ce",
                              width: "325px",
                              padding: "20px 0",
                              fontWeight: "600",
                              color: "#fff",
                              marginTop: "15%",
                              borderRadius: "0px",
                              "&:hover": {
                                backgroundColor: "#097390",
                                color: "#fff",
                              },
                            }}
                          >
                            SUBMIT
                          </Button>
                        </Box>
                      </Form>
                    </Box>
                    <Box
                      sx={{
                        marginTop: "20px",
                        height: "1px",
                        backgroundColor: "#E0E0E0",
                        width: "100%",
                      }}
                    />
                  </Box>
                </>
              )}
            </>
          );
        }}
      </Formik>
    </>
  );
};

export default Contact;
