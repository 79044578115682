import React, { useState } from "react";
import {
  Box,
  Typography,
  useTheme,
  useMediaQuery,
  IconButton,
} from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import LocalImages from "../../utils/localImages";

const Steps = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [currentStep, setCurrentStep] = useState(0);
  const handleNext = () => setCurrentStep((currentStep + 1) % steps.length);
  const handlePrevious = () =>
    setCurrentStep((currentStep - 1 + steps.length) % steps.length); /* Handles circular scrolling */

  const steps = [
    {
      imgSrc: LocalImages.How1,
      title: "Step 1",
      description: "List your job details using our tools.",
    },
    {
      imgSrc: LocalImages.How2,
      title: "Step 2",
      description: "Service providers respond to your request. ",
    },
    {
      imgSrc: LocalImages.How3,
      title: "Step 3",
      description: "You select the best fit for your needs.",
    },
    {
      imgSrc:LocalImages.How4,
      title: "Step 4",
      description: "Your trade of choice provides the requested services.",
    },
  ];

  return (
    <Box sx={{ position: "relative", mt:"50px" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <IconButton
            aria-label="previous"
            onClick={handlePrevious}
            sx={{ mr: "10px" }}
          >
            <NavigateBeforeIcon />
          </IconButton>
          <Box
            sx={{
              display: "flex",
              flexDirection: isMobile ? "column" : "row",
              alignItems: "center",
              mx: "20px",
            }}
          >
            <img
              src={steps[currentStep].imgSrc}
              alt="Step"
              style={{ width: isMobile ? "295"  : "579px", height: isMobile ? "210px" : "412px" }}
            />
            <Box >
            <Typography
              sx={{
                fontSize: "16px",
                color: "#4f4f4f",
                fontWeight: "600",
                mt: "2%",
              }}
            >
              {steps[currentStep].title}
            </Typography>
            <Typography
              sx={{
                width: "249px",
                color: "#4F4F4F",
                fontSize: "14px",
                textAlign: isMobile ? "centre" : "left",
                padding: isMobile ? "20px 0 0 0" : "20px  0 0 170px ",
                ml:"30px"
              }}
            >
              {steps[currentStep].description}
            </Typography>
            </Box>
          </Box>
          <IconButton
            aria-label="next"
            onClick={handleNext}
            sx={{ ml: "10px" }}
          >
            <NavigateNextIcon />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
};

export default Steps;