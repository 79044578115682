import React from "react";
import { Typography, Box, useMediaQuery, useTheme } from "@mui/material";

const How = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(1024));
  return (
    <>
      {!isMobile ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "8%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-evenly",
              alignItems: "center",
              width: "100% !important",
            }}
          >
            <Box
              sx={{ height: "5px", backgroundColor: "#0455bf", width: "40%" }}
            />
            <Typography
              sx={{
                fontSize: "24px",
                fontWeight: "600",
                color: "#4f4f4f",
                width: "20%",
              }}
            >
              HOW IT WORKS
            </Typography>
            <Box
              sx={{ height: "5px", backgroundColor: "#ffffff", width: "40%" }}
            />
          </Box>

          <Typography
            sx={{
              width: "890px",
              color: "#333333",
              fontSize: "16px",
              mt: "2%",
              mb: "2%",
            }}
          >
            Our mission to digitise the search, booking and fulfillment process
            enables access to a wide variety of skilled trades and specialist
            service providers ready to provide direct support.
          </Typography>
        </Box>
      ) : (
        <>
          <Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: "8%",
                width: "100%",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-evenly",
                  alignItems: "center",
                  width: "100% !important",
                }}
              >
                <Box
                  sx={{
                    height: "5px",
                    backgroundColor: "#0455bf",
                    width: "20%",
                  }}
                />
                <Typography
                  sx={{
                    fontSize: "24px",
                    fontWeight: "600",
                    color: "#4f4f4f",
                    width: "60%",
                  }}
                >
                  HOW IT WORKS
                </Typography>
                <Box
                  sx={{
                    height: "5px",
                    backgroundColor: "transparent",
                    width: "20%",
                  }}
                />
              </Box>

              <Typography
                sx={{
                  width: "327px",
                  color: "#333333",
                  fontSize: "16px",
                  mt: "6%",
                }}
                id="how"
              >
                Our mission to digitise the search, booking and fulfillment
                process enables access to a wide variety of skilled trades and
                specialist service providers ready to provide direct support.
              </Typography>
            </Box>
          </Box>
        </>
      )}
    </>
  );
};

export default How;
