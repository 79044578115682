import { Box, Typography, useTheme, useMediaQuery } from "@mui/material";
import LocalImages from "../../utils/localImages";

const Step = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(1024));
  return (
    <>
      {!isMobile ? (
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-evenly",
              width: "100%",
              mt: "3%",
            }}
          >
            <Box>
              <img src={LocalImages.Step1} alt="Step" />
              <Typography
                sx={{ width: "278px", color: "#333333", fontSize: "16px" }}
              >
                Multi- Industry Service providers for all stages of
                Manufacturing and Facility support
              </Typography>
            </Box>
            <Box>
              <img src={LocalImages.Step2} alt="Step" />
              <Typography
                sx={{ width: "278px", color: "#333333", fontSize: "16px" }}
              >
                Booking Process all managed on platform
              </Typography>
            </Box>
            <Box>
              <img src={LocalImages.Step3} alt="Step" />
              <Typography
                sx={{ width: "278px", color: "#333333", fontSize: "16px" }}
              >
                From Shift Maintenance through to full project management and
                Ancillary services
              </Typography>
            </Box>
          </Box>
        </>
      ) : (
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-evenly",
              flexDirection: "column",
              width: "100%",
              mt: "20%",
            }}
          >
            <Box
              sx={{
                alignItems: "center",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <img
                style={{ width: "155px", height: "144px" }}
                src={LocalImages.Step1}
                alt="Step"
              />
              <Typography
                sx={{
                  width: "278px",
                  color: "#333333",
                  fontSize: "14px",
                  padding: "50px 0px",
                }}
              >
                Multi- Industry Service providers for all stages of
                Manufacturing and Facility support
              </Typography>
            </Box>
            <Box
              sx={{
                alignItems: "center",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <img
                style={{ width: "155px", height: "144px" }}
                src={LocalImages.Step2}
                alt="Step"
              />
              <Typography
                sx={{
                  width: "278px",
                  color: "#333333",
                  fontSize: "14px",
                  padding: "50px 0px",
                }}
              >
                Booking Process all managed on platform
              </Typography>
            </Box>
            <Box
              sx={{
                alignItems: "center",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <img
                style={{ width: "155px", height: "144px" }}
                src={LocalImages.Step3}
                alt="Step"
              />
              <Typography
                sx={{
                  width: "268px",
                  color: "#333333",
                  fontSize: "14px",
                  padding: "50px 0px",
                }}
                id="how"
              >
                From Shift Maintenance through to full project management and
                Ancillary services
              </Typography>
            </Box>
          </Box>
        </>
      )}
    </>
  );
};

export default Step;
